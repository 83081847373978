import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private map: H.Map | null;
  private mapInitialized = signal(false);

  constructor() {}

  /**
   * Sets the HERE Map instance in the service.
   * This method is called when the map is initialized.
   * @param _map The map instance that has been initialized
   */
  setMap(_map: H.Map): void {
    this.map = _map;
    this.mapInitialized.set(true); // Set the initialized signal to true
  }

  /**
   * Returns an observable for map initialization state.
   * (Useful for components or services that use reactive behavior.)
   */
  isMapInitialized() {
    return this.mapInitialized.asReadonly();
  }

  /**
   * Returns the HERE Map instance.
   * Throws an error if the map is not yet initialized.
   */
  getMap() {
    if (!this.map) {
      throw new Error('Map has not been initialized!');
    }
    return this.map;
  }
}
