import { Injectable, effect, inject, signal } from '@angular/core';
import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { ChargePointApiService } from '@pages/map/services/charge-point-api.service';
import { ClusteringService } from '@pages/map/services/clustering.service';
import { MapControlService } from '@pages/map/services/map-control.service';
import { isPartner } from '@pages/map/utils/partner-filtering';
import { first } from 'rxjs';

export const InitialMapPosition = {
  lat: '59.165692143635496',
  long: '17.61991628465645',
  radius: 400,
};

export type chargingStationType = 'Truck Charging' | 'Car Charging';

@Injectable()
export class ChargePointService {
  private chargePointAPI = inject(ChargePointApiService);
  private mapUIControls = inject(MapControlService);
  private clusteringService = inject(ClusteringService);

  selectedChargingStationType = signal<chargingStationType>('Truck Charging');

  showTruckChargePoints = this.chargePointAPI.showTruckChargePoints;
  showPassengerCarChargingPoints = this.chargePointAPI.showPassengerCarChargingPoints;
  showLimitedTruckChargingPoints = this.chargePointAPI.showLimitedTruckChargingPoints;

  updateChargerSelection(type: chargingStationType) {
    this.selectedChargingStationType.set(type);
  }

  chargePointsList = signal<IChargePointv2[]>([]);
  visibleChargePoints = signal<IChargePointv2[]>([]);
  visibleMarkers = signal<any>([]);

  calculateVisibleChargePoints() {
    const a = this.chargePointsList().filter((cpo) => {
      return this.mapUIControls.boundingBox?.containsPoint({
        lat: cpo.coordinates.latitude,
        lng: cpo.coordinates.longitude,
      });
    });

    this.visibleChargePoints.set(a);
  }

  constructor() {
    effect(() => {
      this.showTruckChargePoints();
      this.showLimitedTruckChargingPoints();
      this.showPassengerCarChargingPoints();

      this.getChargePoints();
    });
  }

  mapViewChanged() {
    this.calculateVisibleChargePoints();
    this.clusteringService.calculateVisibleClusters();
  }

  getChargePoints() {
    this.chargePointAPI
      .getChargePointsList()
      .pipe(first())
      .subscribe({
        next: (chargePoints: IChargePointv2[]) => {
          const sortedCPOS = this.sortChargePoints(chargePoints);
          this.chargePointsList.set(sortedCPOS);
          this.clusteringService.createCluster(chargePoints);
          this.calculateVisibleChargePoints();
        },
        error: () => {},
        complete: () => {},
      });
  }

  sortChargePoints(chargePoints: IChargePointv2[]): IChargePointv2[] {
    return chargePoints.sort(
      (a, b) => this.compareCPOsPartner(a, b) || this.compareCPOsGrade(a, b) || this.compareCPOsMaxPower(a, b),
    );
  }

  compareCPOsPartner(first: IChargePointv2, second: IChargePointv2): number {
    if (isPartner(first.name) === isPartner(second.name)) {
      return 0;
    } else {
      return isPartner(first.name) && !isPartner(second.name) ? -1 : 1;
    }
  }

  compareCPOsGrade(first: IChargePointv2, second: IChargePointv2): number {
    if (first.truckChargingGrade === second.truckChargingGrade) {
      return 0;
    } else {
      return first.truckChargingGrade < second.truckChargingGrade ? 1 : -1;
    }
  }

  compareCPOsMaxPower(first: IChargePointv2, second: IChargePointv2): number {
    if (first.maxPower === second.maxPower) {
      return 0;
    } else {
      return first.maxPower < second.maxPower ? 1 : -1;
    }
  }

  getChargePointDetail(chargePointId: string) {
    return this.chargePointAPI.getChargePointDetail(chargePointId);
  }
}
