import { HttpClient, HttpHeaders, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { last, Observable } from 'rxjs';
import { AutoComplete, AutoCompleteInterface, LookUp, Discover } from '@pages/map/interfaces/HEREAPI';
import { MapControlService } from '@pages/map/services/map-control.service';

@Injectable()
export class HereApiService {
  apikey = 'wyBnr85mHwleRwtLqvj5fwZ9_g9JnCovZMinW7FxHhs'; // where to store it
  httpClient = inject(HttpClient);

  mapUIControls = inject(MapControlService);

  lat = '';
  lng = '';

  private readonly autoSuggestURL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
  private readonly lookupURL = 'https://lookup.search.hereapi.com/v1/lookup';

  private countryCodes =
    'countryCode:AUT,BEL,BGR,HRV,CYP,CZE,DNK,EST,FIN,FRA,DEU,GRC,HUN,IRL,ITA,LVA,LTU,LUX,MLT,NLD,POL,PRT,ROU,SVK,SVN,ESP,SWE,GBR,ALB,AND,ARM,AZE,BLR,BIH,GEO,ISL,IRL,KAZ,LIE,MDA,MCO,MNE,MKD,NOR,RUS,SMR,SRB,CHE,TUR,VAT,UKR';

  constructor() {}

  formatMapCenterToString() {
    this.lat = this.mapUIControls.mapCenter.lat.toString();
    this.lng = this.mapUIControls.mapCenter.lng.toString();
  }

  getSuggestionQueryParams(searchText: string): HttpParams {
    this.formatMapCenterToString();
    return new HttpParams()
      .set('apiKey', this.apikey)
      .set('at', this.lat + ',' + this.lng)
      .set('in', this.countryCodes)
      .set('lang', 'en-US')
      .set('q', searchText)
      .set('limit', 5);
  }

  getAutoSuggestResults(searchText: string): Observable<AutoComplete> {
    const params = this.getSuggestionQueryParams(searchText);
    return this.httpClient.get(this.autoSuggestURL, { params }) as any;
  }

  lookupSuggestion(result: AutoCompleteInterface): Observable<LookUp> {
    const params = new HttpParams().set('apiKey', this.apikey).set('id', result.id);
    return this.httpClient.get(this.lookupURL, { params }) as any;
  }

  discoverSuggestion(result: AutoCompleteInterface): Observable<Discover> {
    const params = new HttpParams().set('apiKey', this.apikey);
    return this.httpClient.get(result.href + `&apiKey=${this.apikey}`) as any;
  }
}
