import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { MapService } from '@pages/map/services/map.service';

const InitialMapPosition = {
  lat: '60.60395',
  long: '17.76278',
  radius: 400,
};

@Injectable()
export class MapControlService {
  private hereMapService = inject(MapService);
  private mapUI: H.ui.UI;

  mapCenter: H.geo.Point;
  boundingBox: H.geo.Rect | null | undefined;
  latitude: string = InitialMapPosition.lat;
  longitude: string = InitialMapPosition.long;
  radius: number = InitialMapPosition.radius;
  zoomLevel = signal<number>(0);
  behaviour: H.mapevents.Behavior;

  shouldHideItemsAtZoomLevel = computed<boolean>(() => this.zoomLevel() < 8);

  previousPosition: {
    center: H.geo.IPoint;
    zoom: number;
  };

  clusterLayer: H.map.layer.ObjectLayer | undefined;

  constructor() {}

  initialize() {
    const map = this.hereMapService.getMap();
    this.mapUI = new H.ui.UI(map);
    this.setMapBehaviors(map);
    this.setUIControls(map);
  }

  updateMapParams() {
    const map = this.hereMapService.getMap();
    this.mapCenter = map.getCenter();
    this.latitude = this.mapCenter.lat.toString();
    this.longitude = this.mapCenter.lng.toString();
    this.zoomLevel.set(map.getZoom());
    this.boundingBox = map.getViewModel().getLookAtData().bounds?.getBoundingBox();

    console.log('Here Maps Zoom Level', this.zoomLevel());

    // this.useClusterEndPoint = false;
    this.radius = this.calculateRadius();
  }

  calculateRadius(): number {
    let radiusInKM = this.radius;
    if (this.boundingBox) {
      const northWest = this.boundingBox.getTopLeft();
      const southEast = this.boundingBox.getBottomRight();

      // Calculate the distance from the center to the northwest corner (diagonal)
      const distanceToNorthWest = this.mapCenter.distance(northWest);
      const distanceToSouthEast = this.mapCenter.distance(southEast);
      // Get the northwest and southeast corners of the bounding box

      // The radius will be half of the diagonal distance
      const radius = Math.max(distanceToNorthWest, distanceToSouthEast);
      radiusInKM = Math.floor(radius / 1000);
    }

    return radiusInKM < 1 ? 1 : radiusInKM;
  }

  setUIControls(map: H.Map) {
    const layers = map.getLayers();
    // add UI controls
    let ui = H.ui.UI.createDefault(map, layers);

    this.setZoomControls(ui);
    //remove settings from UI
    const mapSettingsControl = ui.getControl('mapsettings');
    mapSettingsControl?.setVisibility(false);
  }

  setZoomControls(ui: H.ui.UI) {
    const zoomControl = ui.getControl('zoom');
    zoomControl?.addClass('custom-zoom-controls');

    if (zoomControl) {
      const zoomInButton = zoomControl.getElement()?.firstChild;
      const zoomOutButton = zoomControl.getElement()?.firstChild?.nextSibling;

      zoomInButton?.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        const currentZoom = this.mapUI.getMap().getZoom();
        this.mapUI.getMap().setZoom(currentZoom + 1, true); // Zoom IN by one level
        this.behaviour.enable();
      });
      zoomOutButton?.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        const currentZoom = this.mapUI.getMap().getZoom();
        this.mapUI.getMap().setZoom(currentZoom - 1, true); // Zoom Out by one level
      });
    }
  }

  setMapBehaviors(map: H.Map) {
    const mapEvents = new H.mapevents.MapEvents(map);
    // Add behavior to the map: panning, zooming, etc.
    this.behaviour = new H.mapevents.Behavior(mapEvents);

    const FRACTIONAL_ZOOM = H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM;
    this.behaviour.disable(FRACTIONAL_ZOOM);
  }

  navigateToPoint(center: H.geo.IPoint, zoom: number) {
    this.savePreviousPosition();
    this.hereMapService.getMap().getViewModel().setLookAtData(
      {
        position: center,
        zoom: zoom,
      },
      10,
    );
  }

  savePreviousPosition() {
    const center = this.hereMapService.getMap().getViewModel().getLookAtData().position!;
    const zoom = this.hereMapService.getMap().getViewModel().getLookAtData().zoom!;

    this.previousPosition = {
      center: center,
      zoom: zoom,
    };
  }

  navigateToPreviousPosition() {
    this.navigateToPoint(this.previousPosition.center, this.previousPosition.zoom);
  }

  navigateToBoundingBox(rect: H.geo.Rect, paddingFactor = 0.5) {
    this.savePreviousPosition();
    const paddedRect = this.addBoundingBoxPadding(rect, paddingFactor);
    this.hereMapService.getMap().getViewModel().setLookAtData({ bounds: paddedRect });
  }

  /**
   * Helper: Add padding to the bounding box to zoom out slightly.
   */
  addBoundingBoxPadding(rect: H.geo.Rect, paddingFactor: number): H.geo.Rect {
    const latPadding = (rect.getTop() - rect.getBottom()) * paddingFactor;
    const lngPadding = (rect.getRight() - rect.getLeft()) * paddingFactor;

    return new H.geo.Rect(
      rect.getTop() + latPadding,
      rect.getLeft() - lngPadding,
      rect.getBottom() - latPadding,
      rect.getRight() + lngPadding,
    );
  }
}
