import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environments/environment';
import { ClusteredChargePoints } from '@pages/map/interfaces/chargePointClustering';
import { IChargePointDetails, IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { MapControlService } from '@pages/map/services/map-control.service';
import { Observable, of, Subject, takeUntil } from 'rxjs';

@Injectable()
export class ChargePointApiService {
  private httpClient = inject(HttpClient);
  private readonly chargePointsURL = environment.chargepoint;
  private readonly clusteringEndPointURL = this.chargePointsURL + 'clusteredList';
  private readonly chargePoingListEndPointURL = this.chargePointsURL + 'list';
  private readonly chargePointsDetailUrl = this.chargePointsURL + 'detail';
  private mapUIControls = inject(MapControlService);

  private clusteringQueryParams: { params: HttpParams };
  private chargePointListQueryParams: { params: HttpParams };
  private chargePointDetailsQueryParams: { params: HttpParams };

  cancelListPointCall = new Subject<void>();

  minPower = signal<number>(150);
  maxPower = signal<number>(1000);

  showTruckChargePoints = signal<boolean>(true);
  showLimitedTruckChargingPoints = signal<boolean>(true); // 2 limited space
  showPassengerCarChargingPoints = signal<boolean>(false); // 1 Car charging, Unspecified

  getTruckChargingGradeParams(): string {
    const paramsArray: string[] = []; // 4 Truck Charging always on

    if (this.showTruckChargePoints()) {
      paramsArray.push('4');
    }

    if (this.showLimitedTruckChargingPoints()) {
      paramsArray.push('2');
    }

    if (this.showPassengerCarChargingPoints()) {
      paramsArray.push('1');
    }
    return paramsArray.join(',');
  }

  constructor() {}

  getZoomLevelForClusteringAPI(): string {
    const zoom = this.mapUIControls.zoomLevel();

    if (zoom < 5) return 'ZoomLevel_1';
    if (zoom < 7) return 'ZoomLevel_2';

    return 'ZoomLevel_3';
  }

  private setQueryParametersForClusteringEndpoint() {
    this.clusteringQueryParams = {
      params: new HttpParams()
        .set('latitude', this.mapUIControls.latitude)
        .set('longitude', this.mapUIControls.longitude)
        .set('radius', 5000)
        .set('minPower', this.minPower())
        .set('zoomLevel', this.getZoomLevelForClusteringAPI())
        .set('truckChargingGrade', this.getTruckChargingGradeParams()),
    };
  }

  getClusteredChargePoints(): Observable<ClusteredChargePoints[]> {
    this.cancelPreviousChargePointListRequest();
    this.setQueryParametersForClusteringEndpoint();
    return this.httpClient
      .get<ClusteredChargePoints[]>(this.clusteringEndPointURL, this.clusteringQueryParams)
      .pipe(takeUntil(this.cancelListPointCall));
  }

  private setQueryParametersforListEndPoint() {
    this.chargePointListQueryParams = {
      params: new HttpParams()
        .set('latitude', this.mapUIControls.latitude)
        .set('longitude', this.mapUIControls.longitude)
        .set('radius', 5000)
        .set('mspPaymentOnly', false)
        .set('truckChargingGrade', this.getTruckChargingGradeParams())
        .set('minPower', this.minPower()),
    };
  }

  getClusteredChargePoints_2(queryParams: Params): Observable<ClusteredChargePoints[]> {
    this.setQueryParametersForClusteringEndpoint();
    return this.httpClient
      .get<ClusteredChargePoints[]>(this.clusteringEndPointURL, queryParams)
      .pipe(takeUntil(this.cancelListPointCall));
  }

  getChargePointsList(): Observable<IChargePointv2[]> {
    this.cancelPreviousChargePointListRequest();
    this.setQueryParametersforListEndPoint();

    if (this.chargePointListQueryParams.params.get('truckChargingGrade')?.length === 0) {
      return of([]);
    }

    return this.httpClient
      .get<IChargePointv2[]>(this.chargePoingListEndPointURL, this.chargePointListQueryParams)
      .pipe(takeUntil(this.cancelListPointCall));
  }

  getChargePointDetail(chargePointId: string) {
    this.setQueryParamsForDetailsEndPoint(chargePointId);
    return this.httpClient.get<IChargePointDetails>(this.chargePointsDetailUrl, this.chargePointDetailsQueryParams);
  }

  private setQueryParamsForDetailsEndPoint(chargePointId: string) {
    this.chargePointDetailsQueryParams = {
      params: new HttpParams().set('chargePointId', chargePointId).set('includeMspPrices', true),
    };
  }

  private cancelPreviousChargePointListRequest() {
    this.cancelListPointCall.next();
  }

  getChargePointListForTrucks() {
    this.setQueryParametersforListEndPoint();
    let paramsForTrucks = this.chargePointListQueryParams.params.set('truckChargingGrade', '2,4');
    return this.httpClient.get<IChargePointv2[]>(this.chargePoingListEndPointURL, { params: paramsForTrucks });
  }

  getChargePointListForCars() {
    this.setQueryParametersforListEndPoint();
    let paramsForCars = this.chargePointListQueryParams.params.set('truckChargingGrade', '1');
    return this.httpClient.get<IChargePointv2[]>(this.chargePoingListEndPointURL, { params: paramsForCars });
  }
}
