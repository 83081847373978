import { Injectable, inject } from '@angular/core';
import { Position } from '@pages/map/interfaces/HEREAPI';
import { customLocationPinSVG } from '@pages/map/assets/markers';
import { MapService } from '@pages/map/services/map.service';
import { MapControlService } from '@pages/map/services/map-control.service';

@Injectable()
export class MapResultsService {
  private hereMapService = inject(MapService);
  private mapControls = inject(MapControlService);

  private customLocationPin = new H.map.Icon(customLocationPinSVG);
  private searchResults: H.map.Group | null;

  initializeSearchResultGroup(map: H.Map) {
    if (this.searchResults) {
      // If the group already exists, clear it
      this.searchResults.removeAll();
    } else {
      // Create a new group and add it to the map
      this.searchResults = new H.map.Group();
      map.addObject(this.searchResults);
    }

    return this.searchResults;
  }

  addResults(positions: Position[]) {
    const map = this.hereMapService.getMap();
    const group = this.initializeSearchResultGroup(map);
    this.clearResultMarkers();

    positions.forEach((position) => {
      const marker = new H.map.Marker(
        {
          lat: position.lat,
          lng: position.lng,
        },
        {
          icon: this.customLocationPin,
          data: '',
          zIndex: 3,
        },
      );

      group.addObject(marker);
    });

    const box = group.getBoundingBox();
    this.mapControls.navigateToBoundingBox(box);
  }

  addResult(position: Position) {
    const map = this.hereMapService.getMap();
    const group = this.initializeSearchResultGroup(map);
    this.clearResultMarkers();

    const marker = new H.map.Marker(
      {
        lat: position.lat,
        lng: position.lng,
      },
      {
        icon: this.customLocationPin,
        data: '',
        zIndex: 3,
      },
    );

    group.addObject(marker);
    this.mapControls.navigateToPoint(position, 10);
  }

  clearResultMarkers() {
    this.searchResults?.removeAll();
  }
}
