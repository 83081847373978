import { effect, inject, Injectable } from '@angular/core';
import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';

import { MapControlService } from '@pages/map/services/map-control.service';
import { MapIconsService } from '@pages/map/services/map-icons.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';
import { MapService } from '@pages/map/services/map.service';

@Injectable()
export class ClusteringService {
  private hereMapService: MapService = inject(MapService);
  private mapUIControls = inject(MapControlService);
  private mapUIStateService = inject(MapUiStateService);
  private mapIconsService = inject(MapIconsService);

  private currentClusterLayer: H.map.layer.ObjectLayer = {} as any;
  private clusteredDataProvider: H.clustering.Provider;
  private hoverSupport = this.mapUIStateService.hoverFeatureAvailable;

  setTheme(): H.clustering.ITheme {
    return {
      getClusterPresentation: (cluster) => {
        const clusterSize = cluster.getWeight();
        const clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: this.mapIconsService.clusterIcons.get(this.mapIconsService.getClusterIconSize(clusterSize)),
          data: cluster,
          volatility: true,
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });

        if (this.hoverSupport) {
          this.attachEventListenerForClusterMarkers(clusterMarker, clusterSize);
        }

        //need to Implement click logic here i guess
        return clusterMarker;
      },
      getNoisePresentation: (noisePoint: H.clustering.INoisePoint): H.map.Object => {
        let data = noisePoint.getData() as IChargePointv2;

        const iconKey = this.mapIconsService.getIconKey(data);

        let noiseMarker = new H.map.Marker(
          noisePoint.getPosition(),
          {
            icon: this.mapIconsService.markerIcons.get(iconKey)!,
            data: data,
            volatility: true,
            min: noisePoint.getMinZoom(),
          },
          // Use min zoom from a noise point
          // to show it correctly at certain zoom levels:
        );

        if (this.hoverSupport) {
          this.attachEventListenerForIndividualMarkers(noiseMarker);
        }

        noiseMarker.setData(data);

        return noiseMarker;
      },
    };
  }

  attachEventListenerForClusterMarkers(marker: H.map.Marker, clusterSize: number) {
    marker.addEventListener('pointerenter', (event: H.mapevents.Event) => {
      // hover
      this.hereMapService.getMap().getElement().style.cursor = 'pointer';
      this.mapIconsService.applyHoverStyleToClusterMarker(marker, clusterSize);
    });

    marker.addEventListener('pointerleave', (event: H.mapevents.Event) => {
      // restore to default
      this.hereMapService.getMap().getElement().style.cursor = 'unset';
      this.mapIconsService.removeHoverStyleFromClusterMarker(marker, clusterSize);
    });
  }

  attachEventListenerForIndividualMarkers(marker: H.map.Marker) {
    marker.addEventListener('pointerenter', (event: H.mapevents.Event) => {
      // hover
      const eventMarker = event.target as H.map.Marker;
      this.hereMapService.getMap().getElement().style.cursor = 'pointer';
      if (this.isSameMarker(eventMarker)) {
        return;
      } else {
        this.mapIconsService.applyHoverStyles(eventMarker);
      }
    });

    marker.addEventListener('pointerleave', (event: H.mapevents.Event) => {
      // restore to default
      const eventMarker = event.target as H.map.Marker;
      this.hereMapService.getMap().getElement().style.cursor = 'unset   ';
      if (this.isSameMarker(eventMarker)) {
      } else {
        this.mapIconsService.removeHoverStyles(eventMarker);
        this.mapUIStateService.hoveredChargePoint.set(undefined);
      }
    });
  }

  attachLayer(layer: H.map.layer.ObjectLayer) {
    this.currentClusterLayer = layer;
    this.hereMapService.getMap().addLayer(layer);
  }

  removeExistingClusterLayer() {
    if (Object.keys(this.currentClusterLayer).length !== 0) {
      this.hereMapService.getMap().removeLayer(this.currentClusterLayer);
    }
  }

  createCluster(chargePoints: IChargePointv2[]): H.map.layer.ObjectLayer {
    // remove previous clusters
    this.removeExistingClusterLayer();

    let dataPoints: H.clustering.DataPoint[] = chargePoints.map((chargePoint) => {
      return new H.clustering.DataPoint(
        chargePoint.coordinates.latitude,
        chargePoint.coordinates.longitude,
        1,
        chargePoint,
      );
    });

    this.clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        minWeight: 2,
        strategy: H.clustering.Provider.Strategy.FASTGRID,
        eps: 32,
      },
      theme: this.setTheme(),
    });

    let layer = new H.map.layer.ObjectLayer(this.clusteredDataProvider);

    this.attachLayer(layer);
    this.attachClusterTapListener();
    return layer;
  }

  calculateVisibleClusters() {
    this.mapIconsService.visibleCluster = this.clusteredDataProvider?.requestMarkers(
      this.mapUIControls.boundingBox!,
      this.mapUIControls.zoomLevel(),
      true,
      false,
    );
  }

  attachClusterTapListener() {
    this.clusteredDataProvider.addEventListener(
      'tap',
      (event: H.mapevents.Event) => {
        // Get the clicked marker - either a cluster or IChargePointv2
        const marker = event.target as H.map.Object;

        const point = marker.getData() as H.clustering.ICluster | IChargePointv2;
        if ('isCluster' in point && point.isCluster()) {
          // It's a cluster

          const cluster = point as H.clustering.ICluster;

          // zoom when cluster clicked until that cluster breaks apart
          this.mapUIControls.navigateToPoint(cluster.getPosition(), cluster.getMaxZoom() + 1);

          // todo remove probably
          // this.setZoomAfterClusterTap(cluster.getPosition());
        } else {
          // It's an IChargePointv2  type load load charge point details
          const chargePoint = point as IChargePointv2;
          const marker2 = marker as H.map.Marker;

          this.mapIconsService.updateActiveChargePointIcon(chargePoint, marker2);

          this.mapUIStateService.selectedChargePoint.set(chargePoint);
          this.mapUIStateService.showChargePointDetails.set(true);
          this.mapUIStateService.hideFilterMenu();

          this.mapUIControls.navigateToPoint(
            {
              lat: chargePoint.coordinates.latitude,
              lng: chargePoint.coordinates.longitude,
            },
            16,
          );
        }
      },
      false,
    );
  }

  isSameMarker(marker: H.map.Marker): boolean {
    //In case of hover for same marker, or active marker
    // this function compares if the marker is same as currently hovered/activated
    if (this.mapIconsService.activeChargePoint?.marker) {
      const cpoInfo = marker.getData() as IChargePointv2;
      const activeCpoInfo = this.mapIconsService.activeChargePoint.marker.getData() as IChargePointv2;

      return cpoInfo.chargePointId === activeCpoInfo.chargePointId;
    }
    return false;
  }
}
